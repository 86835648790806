<template lang="pug">
.content
  .row.body-change-password
    .col-md-12
      div.modal-header
        span.cp-modal-title ĐỔI MẬT KHẨU
        span.cp-modal-btn-close.material-icons(@click="$emit('close')") close
      div.modal-body
        .alert.alert-danger(v-if='error') {{error}}
        form(action='#' autocomplete='off')
          .row
            .col-md-12
              .form-group.custom-form-group(style='position:relative;' :class='[errorArray]')
                label.control-label Nhập mật khẩu hiện tại
                input#passwordOld.form-control(v-model="passwordOld" :type='passwordType' required style='padding-right: 40px !important;')
                i.material-icons.cp-icon-password(@click="switchVisibility" v-if="passwordType === 'text'") visibility
                i.material-icons.cp-icon-password(@click="switchVisibility" v-else) visibility_off
            .col-md-12
              .form-group.custom-form-group(style='position:relative;' :class='[errorArray]')
                label.control-label Mật khẩu mới
                input#passwordNew.form-control(v-model="passwordNew" :type='secondPasswordType' required style='padding-right: 40px !important;' @keyup='checkPassword')
                i.material-icons.cp-icon-password(@click="switchVisibilityNew" v-if="secondPasswordType === 'text'") visibility
                i.material-icons.cp-icon-password(@click="switchVisibilityNew" v-else) visibility_off
            .col-md-12
              .form-group.custom-form-group(style='position:relative;' :class='[errorArray]')
                label.control-label Nhập lại mật khẩu mới
                input#passwordConfirm.form-control(v-model="passwordConfirm" :type='confirmPasswordType' required style='padding-right: 40px !important;' @keyup='checkPassword')
                i.material-icons.cp-icon-password(@click="switchVisibilityConfirm" v-if="confirmPasswordType === 'text'") visibility
                i.material-icons.cp-icon-password(@click="switchVisibilityConfirm" v-else) visibility_off
                small(v-if="!matched" style="color: red") *Mật khẩu không khớp*
      div.modal-footer.cp-custom-footer
        div.row
          div.col-md-6.col-12
            div.text-right
              button.cp-btn-save.btn(@click='submitChangePassword') Lưu thay đổi
          div.col-md-6.col-12
            div.text-left
              button.cp-btn-cancel.btn(@click="$emit('close')") Huỷ bỏ
</template>
<script>
import firebase from 'firebase'
export default {
  data() {
    return {
      error: null,
      passwordOld: null,
      passwordNew: null,
      passwordConfirm: null,
      errorArray: [],
      passwordType: 'password',
      secondPasswordType: 'password',
      confirmPasswordType: 'password',
      matched: true,
      displayDialog: this.show
    }
  },
  methods: {
    switchVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password'
    },
    switchVisibilityNew() {
      this.secondPasswordType = this.secondPasswordType === 'password' ? 'text' : 'password'
    },
    switchVisibilityConfirm() {
      this.confirmPasswordType = this.confirmPasswordType === 'password' ? 'text' : 'password'
    },
    checkPassword() {
      if (this.passwordConfirm !== this.passwordNew) {
        this.matched = false
      } else {
        this.matched = true
      }
    },
    submitChangePassword() {
      if (this.passwordConfirm && this.passwordOld && this.passwordNew && this.matched) {
        var user = firebase.auth().currentUser
        if (user) {
          // xác thực lại mật khẩu cũ
          var credential = firebase.auth.EmailAuthProvider.credential(user.email, this.passwordOld)
          if (credential) {
            user.reauthenticateWithCredential(credential).then((res) => {
              // đổi mật khẩu
              res.user.updatePassword(this.passwordNew).then(() => {
                this.$toast.add({ severity: 'success', summary: 'Thành công', detail: 'Đổi mật khẩu thành công', life: 3000 })
                this.displayDialog = false
                this.$emit('close')
              }).catch((error) => {
                if (error.code === 'auth/weak-password') {
                  this.error = 'Mật khẩu mới phải có ít nhất 6 kí tự.'
                } else if (error.code === 'auth/requires-recent-login') {
                  this.error = 'Hoạt động này là nhạy cảm và yêu cầu xác thực gần đây. Đăng nhập lại trước khi thử lại yêu cầu này.'
                } else {
                  this.error = error
                }
              })
            }).catch((error) => {
              if (error.code === 'auth/wrong-password') {
                this.error = 'Mật khẩu hiện tại không hợp lệ.'
              } else {
                this.error = error
              }
            })
          }
        }
      } else if (this.passwordOld === null || this.passwordNew === null || this.passwordConfirm === null || !this.matched) {
        this.errorArray = ['is-empty', 'has-error']
        this.error = 'Vui lòng kiểm tra lại thông tin.'
      }
    }
  }
}
</script>
<style lang="scss">
:root {
  // Color 
  --color-background-button: #FFD311;
}
.body-change-password {
  width: 584px; margin: 0 auto;
  background-color: white;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 5px;
  .cp-modal-title {
    font-family: 'adobe clean', sans-serif;
    float: left;
    font-size: 17px;
  }
  .cp-icon-password {
    margin-top: -30px; 
    display:block;
    position:absolute;
    right:10px;
    &:hover {
      cursor: pointer;
    }
  }
  .cp-modal-btn-close {
    float: right;
    &:hover {
      cursor: pointer;
    }
  }
  .cp-btn-save {
    background: var(--color-background-button);
    color:black;
    text-transform: none;
    font-size: 14px;
    font-family: 'adobe clean', sans-serif;
    border: 1px solid var(--color-background-button);
    border-radius: 5px;
    width: 160px;
    margin-top: 0 !important;
  }
  .cp-btn-cancel {
    background: white;
    color: black;
    text-transform: none;
    font-size: 14px;
    font-family: 'adobe clean', sans-serif;
    border: 1px solid var(--color-background-button);
    border-radius: 5px;
    width: 160px;
    margin-top: 0 !important;
  }
  .cp-custom-footer {
    padding: 0 0 15px;
    border: 0;
  }
}
@media (max-width: 414px) {
  .body-change-password {
    .cp-custom-footer {
      padding-bottom: 10px;
      .cp-btn-save {
        float: left;
        margin-left: 40px;
        width: 120px;
        padding-left: 22px;
      }
      .cp-btn-cancel {
        float: right;
        margin-right: 40px;
        width: 120px;
      }
    }
  }
}
</style>
